// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Navbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Navbar.tsx");
  import.meta.hot.lastModified = "1734531575330.0566";
}
// REMIX HMR END

import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "@remix-run/react";
import { Button } from "./ui/button";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Navbar({
  data,
  slug
}) {
  const currentSlug = data.params ? data.params.slug : true;
  return <Disclosure as="nav">
      {({
      open
    }) => <div className="py-3">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center min-[880px]:hidden">
              {/* Mobile menu button*/}
              <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-white bg-green-primary hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:bg-green-primary">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
              </Disclosure.Button>
            </div>
            <div className="flex flex-1 items-center justify-center min-[880px]:items-stretch min-[880px]:justify-between">
              <div className="flex flex-shrink-0 items-center text-[23.77px] font-black min-[880px]:text-white">
                <a href="/" className="text-gary-primary font-black">
                  BedriftsFinans
                </a>

                
              </div>
              <div className="hidden min-[820px]:ml-6 min-[880px]:flex">
                <div className="flex">
                  {data.map(item => <Link key={item._id} to={item.slug.current} className={classNames("text-green-primary expand-border ", "rounded-md px-3 py-2 text-base font-bold min-w-5")} aria-current={currentSlug === item.slug.current ? "page" : undefined}>
                      {item.title}
                    </Link>)}
                </div>
                <Button className="py-1 px-4 mx-2">Søk nå</Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="min-[880px]:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {data.map(item => <Disclosure.Button key={item._id} as="a" href={item.slug.current} className={classNames(currentSlug === item.slug.current ? "bg-slate-600 text-green-primary" : "text-green-primary", "block rounded-md px-3 py-2 text-base font-medium ")} aria-current={currentSlug === item.slug.current ? "page" : undefined}>
                  {item.title}
                </Disclosure.Button>)}
            </div>
          </Disclosure.Panel>
        </div>}
    </Disclosure>;
}
_c = Navbar;
var _c;
$RefreshReg$(_c, "Navbar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;